import { IMnAFilterConfigurations, ISwitchingTableSelectedFilters } from "../types/mixAndAssortment";
import { NetRevenue } from "../assets/icons/dashboard";
import { IKeyValueConfiguration, IStats } from "../types/common";
import { faCoins, faScaleUnbalancedFlip } from "@fortawesome/free-solid-svg-icons";

const geoFilter = {
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		first: true,
		disabled: true,
		children: "region",
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		disabled: true,
		children: "storeSegment",
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Segment",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		disabled: true,
		last: true,
	},
};

const overallFilters = {
	country: {
		title: "Country",
		options: [],
		placeholder: "Country",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "dateTimePeriod",
		disabled: false,
	},
	dateTimePeriod: {
		title: "Date Time Period",
		options: [],
		placeholder: "Time Period",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		children: "businessUnit",
		disabled: true,
		sort: { enable: false, order: "asc" },
	},
	businessUnit: {
		title: "Business Unit",
		options: [],
		placeholder: "Business Unit",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		children: "endTimePeriod",
		disabled: true,
	},
	endTimePeriod: {
		title: "End Time Period",
		options: [],
		placeholder: "End Time Period",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		last: true,
		sort: { enable: false, order: "asc" },
	},
};

const decisionFilter = {
	assortmentScenario: {
		title: "Linked Assortment Scenario",
		options: [],
		placeholder: "Linked Assortment Scenario",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		first: true,
		children: "mixScenario",
	},
	mixScenario: {
		title: "Linked Mix Scenario",
		options: [],
		placeholder: "Linked Mix Scenario",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		disabled: true,
		last: true,
	},
};

const portfolioLevelFilterData = [
	{
		label: "Brand",
		value: "Brand",
	},
	{
		label: "Segment",
		value: "Segment",
	},
	{
		label: "SKU",
		value: "SKU",
	},
];

const geoLevelFilterData = [
	{
		label: "Channel",
		value: "Channel",
	},
	{
		label: "Region",
		value: "Region",
	},
	{
		label: "Store Segment",
		value: "Store Segment",
	},
];

const portfolioFilter = {
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		first: true,
		children: "region",
		disabled: true,
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		children: "storeSegment",
		disabled: true,
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Store Segment",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		last: true,
		disabled: true,
	},
};

const geoSummaryFilter = {
	segment: {
		title: "Segment",
		options: [],
		placeholder: "Segment",
		all: true,
		multiple: true,
		defaultSelectAll: true,
		disabled: true,
		first: true,
		children: "brand",
	},
	brand: {
		title: "Brand",
		options: [],
		placeholder: "Brand",
		all: true,
		multiple: true,
		disabled: true,
		defaultSelectAll: true,
		children: "sku",
	},
	sku: {
		title: "SKU",
		options: [],
		placeholder: "SKU",
		all: true,
		multiple: true,
		disabled: true,
		defaultSelectAll: true,
		last: true,
	},
};

const analysisFilterOrder = [
	{
		key: "country",
		order: 1,
	},
	{
		key: "dateTimePeriod",
		order: 2,
	},
	{
		key: "businessUnit",
		order: 3,
	},
	{
		key: "endTimePeriod",
		order: 4,
	},
];

const geoFilterOrder = [
	{
		key: "channel",
		order: 1,
	},
	{
		key: "region",
		order: 2,
	},
	{
		key: "storeSegment",
		order: 3,
	},
];

const decisionFilterOrder = [
	{
		key: "assortmentScenario",
		order: 1,
	},
	{
		key: "mixScenario",
		order: 2,
	},
];
const portfolioFilterOrder = [
	{
		key: "channel",
		order: 1,
	},
	{
		key: "region",
		order: 2,
	},
	{
		key: "storeSegment",
		order: 3,
	},
];

const geoSummaryFilterOrder = [
	{
		key: "segment",
		order: 1,
	},
	{
		key: "brand",
		order: 2,
	},
	{
		key: "sku",
		order: 3,
	},
];

const switchingTableDefaultFilters: ISwitchingTableSelectedFilters = {
	segment: [],
	brand: [],
	subBrand: [],
	packSize: [],
	sku: [],
	flavor: [],
	checkoutSku: [],
};

const sizeOfPrizeRadio = {
	options: [
		{
			id: "1",
			label: "Assortment",
			value: "assortmentOpportunities",
			inline: "true",
		},
		{
			id: "2",
			label: "Mix",
			value: "mixOpportunities",
			inline: "true",
		},
	],
	defaultOption: { id: "1", value: "assortmentOpportunities" },
	showRadioButton: true,
};

const KPIData: Array<IStats> = [
	{
		icon: NetRevenue,
		title: "Net Revenue",
		value: "0",
		raise: false,
		change: "2%",
	},
	{
		icon: faCoins,
		color: "#008cca",
		title: "Profit % of NR",
		value: "0",
		raise: true,
		change: "0%",
	},
	{
		icon: faScaleUnbalancedFlip,
		color: "#00a84d",
		title: "VOLUME",
		value: "0",
		raise: false,
		change: "0%",
		className: "row1NetRevenue",
	},
];

const overallFilterOptions: IMnAFilterConfigurations = {
	businessUnit: [],
	country: [],
	dateTimePeriod: [],
	endTimePeriod: [],
	channel: [],
	region: [],
	storeSegment: [],
	mixScenario: [],
	assortmentScenario: [],
	portfolioGeo: {
		channel: [],
		region: [],
		storeSegment: [],
		level: "",
	},
	geoSummary: {
		level: "",
		segment: [],
		brand: [],
		sku: [],
	},
};

const simulationDataKeys = [
	{
		value: "goodToHaveSkusDistribution",
		label: "Good-to-Have SKUs distribution",
	},
	{
		value: "mustHaveSkusDistribution",
		label: "Must-Have SKUs distribution",
	},
	{
		value: "status",
		label: "Status",
	},
	{
		value: "netRevenue",
		label: "Net Revenue",
	},
	{
		value: "nopbt",
		label: "NOPBT",
	},
	{
		value: "volumeMix",
		label: "Volume Mix",
	},
	{
		value: "netPriceRealisation",
		label: "Net Price Realisation",
	},
	{
		value: "priceChangeVsInflation",
		label: "Price change vs Inflation",
	},
	{
		value: "priceChangeVsRestOfMarket",
		label: "Price Change vs Rest of Market",
	},
	{
		value: "exhibition",
		label: "Exhibition",
	},
	{
		value: "shelfSpace",
		label: "Shelf Space",
	},
	{
		value: "assortment",
		label: "ASSORTMENT",
	},
	{
		value: "mix",
		label: "MIX",
	},
	{
		value: "price",
		label: "PRICE",
	},
	{
		value: "shelfAndMerchandising",
		label: "SHELF & MERCHANDISING",
	},
];

const dataObject = {
	sizeOfPrizeRadio,
	KPIData,
	geoFilter,
	overallFilters,
	decisionFilter,
	portfolioFilter,
	geoSummaryFilter,
	geoSummaryFilterOrder,
	geoLevelFilterData,
	portfolioLevelFilterData,
	overallFilterOptions,
	analysisFilterOrder,
	switchingTableDefaultFilters,
	geoFilterOrder,
	decisionFilterOrder,
	portfolioFilterOrder,
	simulationDataKeys,
};

export default dataObject;
